import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import WalletService from "../../services/wallet.service";
import {setMessage} from "./message";
import ReportService from "../../services/report.service";
import {ERROR_BAD_REQUEST, TRANSACTION_TYPE_WITHDRAW} from "../../utils/constants";
import moment from "moment";
import TransactionService from "../../services/transaction.service";
import {calculateDaywiseAmountSpent, getEncodedJson} from "../../utils/utility.service";
import {logout} from "./auth";
import {setLatestTransactionHistory} from "./transaction";
import TransactionStatsService from "../../services/transactionStats.service";


export const getMonthOptionList = createAsyncThunk(
    "report/getMonthList",
    async ({userId, walletId}, thunkAPI) => {
        try {
            const response = await ReportService.getAllMonthList(userId,walletId);

            if(response && response.data) {
                let modifiedMonthList = []; // MMYYYY
                response.data.map((item) => {
                    let monthNumber = parseInt(item.code.substr(0,2));
                    let yearNumber = item.code.slice(-4);
                    let monthName = moment(monthNumber, 'MM').format('MMM');
                    modifiedMonthList.push({ text: monthName + " " +yearNumber , code: item.code });
                })
                return modifiedMonthList;
            }

            return response.data;
        } catch (error) {
            console.log(error);
            const message =
                (error.response && error.response.data && error.response.data.errorMessage) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getMonthlyReport = createAsyncThunk(
    "report/getMonthlyReport",
    async ({walletId, filter},thunkAPI) => {
        try {
            const response = await TransactionStatsService.searchTransactionData(walletId, getEncodedJson(filter));

            if(response && response.data && response.data.length > 0) {
                const modifiedList = calculateDaywiseAmountSpent(response.data);
                thunkAPI.dispatch(setMonthlyReportData(modifiedList));
                return modifiedList;
            }
           return [];
        } catch (error) {
            console.log("direct error, it may token expired");
            if(error.response && error.response.status == ERROR_BAD_REQUEST) {
                thunkAPI.dispatch(logout());
            }
            console.log(error);
        }
    }
)

const initialState = {};

const reportSlice = createSlice({
    name: "report",
    initialState: {
        data: null,
        monthlyReport: null
    },
    reducers: {
        setReportData: (state, action) => {
            state.data = action.payload;
        },
        setMonthlyReportData: (state, action) => {
            state.monthlyReport = action.payload;
        }

    }
})
export const reportData = (state) => {
    return state.report;
}

const {reducer, actions} = reportSlice;

export const { setReportData, setMonthlyReportData } = actions;
export default reducer;