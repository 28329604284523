import '../../assets/styles/reports/reports.scss';
import MenuItem from "@mui/material/MenuItem";
import {TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {allCategoryList, getAllCategory} from "../../store/slices/category";
import {allSubCategoryList, getAllSubCategory} from "../../store/slices/subCategory";
import {useDispatch, useSelector} from "react-redux";
import {getMonthlyReport, getMonthOptionList, reportData} from "../../store/slices/reports";
import {allTransactionStats} from "../../store/slices/transactionStats";
import RecentTransactions from "../../components/transaction/RecentTransactions";
import {calculateTotalSpentSoFar, getTitleFromCategory} from "../../utils/utility.service";
import ApexBarChart from "../../components/reports/charts/ApexBarChart";
import CircularLoading from "../../components/splash/CircularLoading";

const ReportPage = () => {

    const dispatch = useDispatch();
    const {defaultWallet: wallet} = useSelector((state) => state.wallet);
    const {settings: userSettings} = useSelector((state) => state.userProfile);
    const {categoryList} = useSelector(allCategoryList);
    const {subCategoryList} = useSelector(allSubCategoryList);

    const [monthOptionList, setMonthOptionList] = useState(null);
    const [selectValue, setSelectValue] = useState(0);
    const [totalAmountSpentMonthwise, setTotalAmountSpentMonthwise] = useState(0);
    const [reportMonthlyData, setReportMonthlyData] = useState(null);
    const [categoryChartData, setCategoryChartData] = useState(null);

    useEffect(() => {
        if (wallet && !monthOptionList) {
            dispatch(getMonthOptionList({ userId: wallet.userId,  walletId: wallet.id}))
                .unwrap()
                .then((response) => {
                    setMonthOptionList(response);

                    if(response.length > 0) {
                        let defaultSelectedValue = response[0].code;
                        setSelectValue(defaultSelectedValue);
                        loadMonthlyData(defaultSelectedValue);
                    }
                })
                .catch(() => {

                })
        }

    }, [monthOptionList]);

    const handleChange = (event) => {

        const {name, value} = event.target;
        setSelectValue(value);
        loadMonthlyData(value);
    }

    const loadMonthlyData = (value) => {

        const filter = {'month-wise': {'enable': true, 'value': value}};

        if (wallet && value && value.length == 6) {

            dispatch(getMonthlyReport({ walletId: wallet.id, filter }))
                .unwrap()
                .then((response) => {
                    setReportMonthlyData(response);

                    categoryChartConfig(response);
                    setTotalAmountSpentMonthwise(calculateTotalSpentSoFar(response));
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }


    const categoryChartConfig = (monthlyData) => {
        if(monthlyData) {

            let data = [];
            let categoryMap = [];
            let totalAmountSpent = 0;

            monthlyData.map(transaction => {
                if(transaction.isExclude) {
                    return; // skipping exclude items in the report
                }

                let indexFound = categoryMap.findIndex(data => data.categoryId === transaction.categoryId);
                if (categoryMap[indexFound]) {
                    categoryMap[indexFound] = {
                        ...categoryMap[indexFound],
                        amount: Number(categoryMap[indexFound].amount) + Number(transaction.amount)
                    }
                } else {
                    categoryMap.push({
                        categoryId : transaction.categoryId,
                        category: getTitleFromCategory(transaction.categoryId, categoryList),
                        amount: transaction.amount
                    });
                }
            });


            let chartCategory = [];
            if(categoryMap) {
                categoryMap.sort((a, b) => b.amount - a.amount);

                let dataSeries= [];
                categoryMap.map((item) => {
                    chartCategory.push(item.category);
                    dataSeries.push(item.amount);
                });

                let chartData = {
                    categories: chartCategory,
                    chartSeries: [
                        {
                            name: 'Category',
                            data: dataSeries
                        }
                    ]
                }

                setCategoryChartData(chartData);
                //setCategoryChartData(categoryMap.slice(0, 5));
            }
        }
    }

    return (
        <div className="report-page-container header-top-padding">
            <div className="form-header">

                <TextField select label="Select"
                           className="field-select"
                           label="Month"
                           value={ selectValue }
                           name="Month"
                           onChange={handleChange}
                >
                    <MenuItem value="0"> Select month </MenuItem>)
                    {
                        monthOptionList ? monthOptionList.map((option) => {
                            return (<MenuItem value={option.code}
                                              id={option.code}
                                              key={option.code}>
                                {option.text}
                            </MenuItem>)
                        }) : ""
                    }
                </TextField>
            </div>

            <div className="total-amount-spent">
                <div>
                    <span>Total amount spent</span>
                    <span>{userSettings ? userSettings.currency : ''} {totalAmountSpentMonthwise.toFixed(2)}</span>
                </div>
            </div>

            <div className="categoryChart">
            {
                categoryChartData ?
                    <ApexBarChart
                        chartTitle="Expense by category"
                        data={ categoryChartData }
                        xFieldValue="category"
                        yFieldValue="amount"
                    /> : ''
            }
            </div>

            <div className="">
                {
                    reportMonthlyData ? reportMonthlyData.length > 0 ?
                        <RecentTransactions transactionList={reportMonthlyData}/> :
                        <span className="no-recent-transaction">No recent transactions</span> : <CircularLoading enableFlag={true}/>
                }
            </div>
        </div>
    )
}

export default ReportPage;