import moment from "moment";
import {NOTIFICATION_ACTIVE, TRANSACTION_TYPE_CREDIT, TRANSACTION_TYPE_WITHDRAW} from "./constants";
import dayjs from "dayjs";


var quarterOfYear = require('dayjs/plugin/quarterOfYear');
dayjs.extend(quarterOfYear)

export const getTitleFromCategory = (id, categoryList) => {
    if (categoryList) {

        let filterList = categoryList.filter((category) => category.id == id);

        if (filterList[0]) {
            return filterList[0].title;
        }

        return "";
    }
    return "";
}

export const getCategoryObject = (id, categoryList) => {
    if (categoryList) {

        let filterList = categoryList.filter((category) => category.id == id);

        if (filterList[0]) {
            return filterList[0];
        }

        return "";
    }
    return "";
}

export const findTransactionDetails = (id, transactionList) => {
    if (transactionList) {

        let filterList = transactionList.filter((transaction) => transaction.id == id);
        if (filterList.length > 0)
            return filterList[0];
    }
    return null;
}

export const getSubCategoryListByCategory = (categoryId, subCategoryList) => {
    if (subCategoryList) {
        let list = subCategoryList.filter((subCategory) => subCategory.categoryId == categoryId);

        if(list.length > 0) {
            // Filtering Other sub-category and sort all the element and add other at last position
            let otherElement = list.filter(e => e.title === 'Other');
            let result = list.filter(e => e.title !== 'Other')
                        .sort((a, b) => a.title.localeCompare(b.title)).concat(otherElement);
            return result;
        }
        return list;
    }
    return "";
}

export const getDefaultWallet = (walletList) => {
    if (walletList && walletList.length > 0) {
        let defaultWallet = walletList.filter((wallet) => wallet.isDefault)[0];
        return defaultWallet;
    }

    return null;
}

export const camelCase = (str) => {
    return (" " + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function (match, chr) {
        return chr.toUpperCase();
    });
}

export const isBothDatesDifferent = (date1, date2) => {

    const d1 = new Date(date1);
    const d2 = new Date(date2);

    if (
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()
    ) {
        return false;
    }

    return true;
}

export const calculateTotalSpentSoFar = (transactionList) => {

    let totalAmount = 0;
    transactionList.map((transaction) => {
        if (!transaction.isExclude && transaction.transactionType == TRANSACTION_TYPE_WITHDRAW) {
            totalAmount = totalAmount + Number(transaction.amount);
        } else if (!transaction.isExclude && transaction.transactionType == TRANSACTION_TYPE_CREDIT) {
            totalAmount = totalAmount - Number(transaction.amount);
        }
    });

    return totalAmount;
}

export const calculateTotalTransactionsAmount = (transaction, totalSum) => {

    if (!transaction.isExclude && transaction.transactionType == TRANSACTION_TYPE_WITHDRAW) {
        totalSum = totalSum + Number(transaction.amount);
    } else if (!transaction.isExclude && transaction.transactionType == TRANSACTION_TYPE_CREDIT) {
        totalSum = totalSum - Number(transaction.amount);
    }
    return totalSum;
}

export const addNewTransactionToList = (transactionList, transaction) => {

    transactionList.push(transaction);
    transactionList.sort((a, b) => new Date(b.paidOn) - new Date(a.paidOn));
    return calculateDaywiseAmountSpent(transactionList);
}

export const calculateDaywiseAmountSpent = (transactionList) => {
    let dateMap = {};

    transactionList.map((transaction) => {

        let date = moment(transaction.paidOn).format("DDMMYY")

        if (!transaction.isExclude && transaction.transactionType == 0) {

            if (dateMap[date]) {
                dateMap[date] = Number(dateMap[date]) + Number(transaction.amount);
            } else {
                dateMap[date] = transaction.amount;
            }
        } else {
            dateMap[date] = dateMap[date] ? dateMap[date] : 0;
        }
    });

    let sortedList = [];
    transactionList.map((transactionObj, index) => {
        let transaction = Object.assign({}, transactionObj);

        let dateHeader = true;
        if (index > 0 && transactionList[index - 1]) {
            dateHeader = isBothDatesDifferent(transaction.paidOn, transactionList[index - 1].paidOn)
            delete transaction.dateHeaderTotalAmount; // delete if any existing while adding new transaction
        }

        if (dateHeader) {

            let date = moment(transaction.paidOn).format("DDMMYY");
            transaction = {
                ...transaction,
                dateHeaderTotalAmount: dateMap[date]
            }
        }

        sortedList.push(transaction);

    });
    return sortedList;
}

export const getEncodedJson = (value) => {
    return encodeURIComponent(JSON.stringify(value))
}

export const isFallUnderRecentTransactions = (paidOn, daysCounts) => {
    let moment_days_ago = moment().subtract(daysCounts, 'days');
    moment_days_ago.set({hour:0,minute:0,second:0,millisecond:0}); 
    return moment(paidOn).isSameOrAfter(moment_days_ago);
}

export const isTransactionsFallUnderPassMonthCounts = (paidOn, monthCounts) => {
    let moment_days_ago = moment().subtract(monthCounts, 'months');
    return moment(paidOn).isSameOrAfter(moment_days_ago);
}

export const getReminderTypeTitle = (value) => {
    let title = '';
    switch (parseInt(value)) {
        case 0:
            title = "None";
            break;
        case 1:
            title = "Daily";
            break;
        case 2:
            title = "Weekly";
            break;
        case 3:
            title = "Bi Weekly";
            break;
        case 4:
            title = "Monthly";
            break;
        case 5:
            title = "Quarterly";
            break;
        case 6:
            title = "Half Yearly";
            break;
        case 7:
            title = "Yearly";
        case 8:
            title = "Custom";
    }
    return title;
}

export const getAddValueAsPerDayjs = (val) => {
    let value = '';
    switch (parseInt(val)) {
        case 0:
            value = ""; // none
            break;
        case 1:
            value = "d";
            break;
        case 2:
            value = "w";
            break;
        case 3:
            value = "2w";
            break;
        case 4:
            value = "M";
            break;
        case 5:
            value = "Q";
            break;
        case 6:
            value = "2Q";
            break;
        case 7:
            value = "y";
            break;
        case 8:
            value = "Custom";
    }
    return value;
}

export const getActiveNotificationCount = (notificationList) => {
    let count = 0;
    notificationList.map((notify) => {
        if(notify.state == NOTIFICATION_ACTIVE) {
            count++;
        }
    });
    return count == 0 ? 0 : ''+count;
}

/*export const getDayStr = (value) => {
    var isToday = require('dayjs/plugin/isToday')

    //dayjs.extend(isToday)

    return dayjs(value).fromNow() + ""; // true
}
*/
export const printTest = () => {

    let m1 = dayjs();
    let m2 = dayjs().valueOf()
    console.log("#######################")
    console.log(m1);
    console.log(m2);
    console.log("#######################")
}

export const getNextReminderDate = (stDate, edDate, repeatVal) => {

    let startDate = dayjs(stDate);
    let repeat = getAddValueAsPerDayjs(repeatVal);
    let nextReminderDate;

    if(repeat.length ==1 ) {
        nextReminderDate = startDate.add(1, repeat);
    } else if(repeat.length > 1) {
        let firstIndex = repeat.charAt(0);
        let lastIndex = repeat.charAt(1);
        nextReminderDate = startDate.add(firstIndex, lastIndex);
    } else {
        // if no repeat set or None
        return "";
    }

    return nextReminderDate;
}
export const getNextReminderDaysCount = (upcomingReminder) => {

/*
    let endDate = dayjs(endDate);
    if(repeat =="" || dayjs().isAfter(endDate)) {
        return "";
    }
*/
/*    debugger;
    let startDate = dayjs(stDate);
    let nextReminderDate;

    if(repeat.length ==1 ) {
        nextReminderDate = startDate.add(1, repeat);
    } else if(repeat.length > 1) {
        let firstIndex = repeat.charAt(0);
        let lastIndex = repeat.charAt(1);
        nextReminderDate = startDate.add(firstIndex, lastIndex);
    } else {
        // if no repeat set or None
        return "";
    }*/
debugger
    let nextDate = dayjs(upcomingReminder);
    console.log("#############################");
    console.log(nextDate);
    console.log(dayjs());
    return (nextDate.diff(dayjs(), 'day') + 1); // adding 1 bcoz to set the correct count
}

// Adding remaining days count in the reminder list
export const updatedReminderList = (reminderList) => {

    debugger;
    let updatedListWithCount = [];
    reminderList.map((reminderObj) => {
        let reminder = Object.assign({}, reminderObj);
        reminder = {
            ...reminder,
            remainingDaysCount: (reminderObj.state == NOTIFICATION_ACTIVE) ? getNextReminderDaysCount(reminder.upcomingReminder) : 0
        }
        updatedListWithCount.push(reminder);
    });

    let sortAsPerRemainingDays = updatedListWithCount.sort((a, b) => ((a.remainingDaysCount - b.remainingDaysCount)));
    let result = sortAsPerRemainingDays.sort((a, b) => ((b.state-a.state)));
    return result;
}